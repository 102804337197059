/* eslint-disable no-underscore-dangle */
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import type { RootState } from '@/store'
import { useSearchParams } from 'react-router-dom'

export const useBuryingPoint = () => {
  const { adChannelCode, channelValue } = useSelector((state: RootState) => state.channel)
  const [search] = useSearchParams()
  const [ksCallBack, setKsCallBack] = useState('')
  // 百度埋点，引入百度sdk
  const baiduBuryingPoint = () => {
    // 创建 script 元素
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD'

    // 将 script 插入到页面中
    const firstScriptTag = document.getElementsByTagName('script')[0]
    if (firstScriptTag && firstScriptTag.parentNode) {
      firstScriptTag.parentNode.insertBefore(script, firstScriptTag)
    }

    // 触发百度埋点的初始化代码
    window._agl = window._agl || []
    window._agl.push(['production', '_f7L2XwGXjyszb4d1e2oxPybgD'])
  }

  // 快手埋点，获取快手callback参数
  const ksBuryingPoint = () => {
    search.get('callback') && setKsCallBack(search.get('callback') as string)
    let ks = search.get('callback')
    ks && localStorage.setItem('ksCallBack', ks)
  }

  useEffect(() => {
    if (channelValue?.includes('bdVid')) {
      baiduBuryingPoint()
    }
    if (channelValue?.includes('ksBurying')) {
      ksBuryingPoint()
    }
  }, [channelValue])
}