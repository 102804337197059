import { USER_INFO } from '@/constants/storage'
import { UserInfo } from '@/types/apis/user'
// import { UserInfo } from '@/apis/types/user'
import { getToken, removeToken } from '@/utils/user'
import { Storage } from '@bihu/common-js'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './index'
import indexApi from '@/apis/index'
import activityApi from '@/apis/activity'

export type PermissionMap = Record<string, boolean> | null;

export interface UserState {

  /** 是否已登录 */
  isLoggedIn: boolean,

  /** 当前用户 */
  userInfo: UserInfo | null;

  /** 当前获取用户信息的状态 */
  getUserInfoStatus: 'initial' | 'loading' | 'success' | 'failed';
}

const initialState: UserState = {
  isLoggedIn: !!getToken(),
  userInfo: Storage.get(USER_INFO) || null,
  getUserInfoStatus: 'initial',
}

// 获取用户信息的 promise
let getLoginUserPromise: Promise<UserInfo> | null = null
// 缓存获取用户信息
const getLoginUserInfoCache = async(ignoreCache = false) => {
  if (getLoginUserPromise && !ignoreCache) {
    return getLoginUserPromise
  }

  getLoginUserPromise = indexApi.getLoginUserInfo()
  const userSource:any = await activityApi.getUserDetailVO()
  try {
    const user:any = await getLoginUserPromise
    if (user.data && userSource) {
      user.data.adChannelId = userSource.adChannelId
    }
    return user
  } catch (e) {
    getLoginUserPromise = null
    throw e
  }
}

/** 调接口获取用户信息 */
export const getUserAsync = createAsyncThunk(
  'user/getUserAsync',
  async(ignoreCache?: boolean) => {
    const res:any = await getLoginUserInfoCache(ignoreCache)
    return res.data || {}
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // 定义 reducer 和关联的 actions
  reducers: {
    // 设置用户登录状态
    setIsLoggedIn: (state, action: PayloadAction<UserState['isLoggedIn']>) => {
      state.isLoggedIn = action.payload
    },
    // 设置当前用户信息
    setUserInfo: (state, action: PayloadAction<UserState['userInfo']>) => {
      Storage.set(USER_INFO, action.payload)
      state.userInfo = action.payload
    },
    // 设置当前获取用户信息的状态
    setGetUserInfoStatus: (state, action: PayloadAction<UserState['getUserInfoStatus']>) => {
      state.getUserInfoStatus = action.payload
    },
    // 退出
    logout: state => {
      removeToken()
      state.isLoggedIn = false
      state.userInfo = null
      state.getUserInfoStatus = 'initial'
      getLoginUserPromise = null
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(getUserAsync.pending, state => {
        state.getUserInfoStatus = 'loading'
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.getUserInfoStatus = 'success'
        state.userInfo = action.payload
      })
      .addCase(getUserAsync.rejected, state => {
        state.getUserInfoStatus = 'failed'
      })
  }
})

export const {
  setIsLoggedIn,
  setUserInfo,
  setGetUserInfoStatus,
  logout,
} = userSlice.actions

// 以下方法为选择器，用于选择 state 中的变量
// 也可以在需要的地方直接写 `useSelector((state: RootState) => state.counter.value)` 来引用变量
export const selectIsLoggedIn = (state: RootState) => state.user.isLoggedIn
export const selectUserInfo = (state: RootState) => state.user.userInfo
export const selectGetUserInfoStatus = (state: RootState) => state.user.getUserInfoStatus

export default userSlice.reducer
