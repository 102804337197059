/* eslint-disable no-underscore-dangle */
/* eslint-disable no-bitwise */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './styles/app.module.scss'
import { useLocation, Outlet, useSearchParams, useNavigate } from 'react-router-dom'
import { getRoute, routes } from './router'
import MainLayout from '@/pages/main-layout/main-layout'
import { TOKEN_INVALID } from './event/user'
import { useEvent } from './event'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { logout } from './store/user'
import { getUserAsync, selectIsLoggedIn, selectUserInfo } from '@/store/user'
// import activityApi from './apis/activity'
import { ApplyResponse } from '@/apis/product'
import { Modal } from 'antd-mobile'
import Event from '@/event'
import { APPLY_STATUS_MODAL } from '@/event/constant'
import { TOKEN } from '@/constants/storage'
import { Storage } from '@bihu/common-js'
import RumSDK from '@arms/rum-browser/dist/browser-sdk'
import { useInitializeAdChannelCode } from '@/hooks/channel'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { useTitleAndFavicon } from './hooks/title-and-favicon'

interface ApplyResponseType extends ApplyResponse{
  productName: string
}

const App: React.FC = () => {
  // 全局渠道管理和特殊渠道区分
  useInitializeAdChannelCode()
  // 全局管理title和favicon图标
  useTitleAndFavicon()
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const location = useLocation()
  const navigate = useNavigate()
  const event = useEvent()
  const dispatch = useAppDispatch()
  const [search] = useSearchParams()

  // 是否已登录
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  // 用户信息
  const userInfo = useAppSelector(selectUserInfo)
  // let interval = useRef<ReturnType<typeof setInterval>>()
  // // 当前页面是否可见
  // const [visibilityState, setVisibilityState] = useState<'hidden' | 'visible'>('visible')

  // useEffect(() => {

  //   if (isLoggedIn) {

  //     if (interval.current) {
  //       clearInterval(interval.current)
  //     }

  //     interval.current = setInterval(() => {
  //       if (visibilityState === 'visible') {
  //         // 上报用户在线心跳
  //         activityApi.health()
  //       }
  //     // eslint-disable-next-line no-magic-numbers
  //     }, 5000)
  //   }

  //   return () => {
  //     clearInterval(interval.current)
  //   }
  // }, [isLoggedIn, visibilityState])

  // 监听页面是否可见
  // useEffect(() => {
  //   const visibilitychange = () => {
  //     setVisibilityState(document.visibilityState)
  //   }
  //   document.addEventListener('visibilitychange', visibilitychange)

  //   return () => {
  //     document.removeEventListener('visibilitychange', visibilitychange)
  //   }
  // }, [])

  const getEnvironment:() => 'local' | 'pre' | 'prod' = () => {
    if (process.env.NODE_ENV === 'development') {
      return 'local' // local表示本地环境
    } else if (process.env.REACT_APP_API_ENV === 'development') {
      return 'pre' // pre表示预发环境
    } else {
      return 'prod' // prod表示线上环境
    }
  }

  const applyStatusModalCallBack = async(obj:ApplyResponseType) => {

    if (obj.applyStatus === 0) {
      Modal.alert({
        content: '该产品销售火爆，请关注其他商品或更换手机号登录后尝试',
        onConfirm: () => {
          // console.log('Confirmed')
        },
      })
    } else if (obj.applyStatus === 2) {
      const result = await Modal.confirm({
        content: '该手机号已注册，点击确认跳转下载页面',
      })
      if (result) {
        window.location.href = `/inline-page?url=${obj.thirdpartyTargetUrl}${obj.productName ? `&productName=${obj.productName}` : ''}`
      }
    }
  }

  useEffect(() => {
    // token 过期，退出登录
    event.on(TOKEN_INVALID, () => {
      dispatch(logout())
      // 登陆页
      // window.location.href = '/login'
      // token过期的，统一跳转到注册完件页，有adChannelCode就带adChannelCode过去
      // const adChannelCode = search.get('adChannelCode')
      window.location.href = `/getQuota${adChannelCode ? `?adChannelCode=${adChannelCode}` : ''}`
    })

    Event.off(APPLY_STATUS_MODAL, applyStatusModalCallBack)
    Event.on(APPLY_STATUS_MODAL, applyStatusModalCallBack)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // 获取用户信息
    const getUserAsyncFn = async() => {
      await dispatch(getUserAsync(true))
    }

    Storage.get(TOKEN) && isLoggedIn && getUserAsyncFn()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  // 是否需要显示主布局
  const hasMainLayout = useMemo(() => {
    const route = getRoute(location.pathname)
    return route ? route.hasMainLayout : false
  }, [location])

  // 阿里云实时应用监控
  const config = {
    pid: 'gndt3gpkvt@7dd815d50e50eb7',
    endpoint: 'https://gndt3gpkvt-default-cn.rum.aliyuncs.com',
    env: getEnvironment(),
    version: '1.0.3'
  }

  // npm形式
  useEffect(() => {
    RumSDK.init(config)
  }, [])

  // 阿里云实时应用监控
  useEffect(() => {
    if (userInfo?.uid) {
      const sdkConfig = RumSDK.getConfig()
      RumSDK.setConfig({
        ...sdkConfig,
        user: {
          name: userInfo.uid
        }
      })
    }
  }, [userInfo])

  const themeChange = (color: string, linearColor:string) => {
    document.documentElement.style.setProperty('--color-primary', color)
    document.documentElement.style.setProperty('--color-primary-r', parseInt(color.slice(1, 3), 16).toString())
    document.documentElement.style.setProperty('--color-primary-g', parseInt(color.slice(3, 5), 16).toString())
    document.documentElement.style.setProperty('--color-primary-b', parseInt(color.slice(5, 7), 16).toString())
    const linearGradient = `linear-gradient(108.61deg, ${linearColor} 3.46%, ${color} 97.1%) `
    document.documentElement.style.setProperty('--linear-gradient', linearGradient)
  }

  // 主题切换
  useEffect(() => {
    if (adChannelCode) {
      // 渠道皮肤
      switch (true) {
        case channelValue?.includes('hbjf'):
          // document.title = '\u200B'
          return
        case channelValue?.includes('jufu'):
          // document.title = '聚客融'
          themeChange('#5255e7', '#A359E2')
          return
        default:
          themeChange('#FF2C3E', '#FF606E')
      }
    }
  }, [channelValue])

  return <div className={styles.app} id="app">
    {
      hasMainLayout ? <MainLayout><Outlet/></MainLayout> : <Outlet/>
    }
  </div>
}

export default App
