import React, { Suspense } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  RouteProps
} from 'react-router-dom'
import App from '@/app'
import { traverseTree } from '@/utils/utils'
// 页面加载中组件
// import LoadingPage from '@/components/loading-page/loading-page'
// 首页
import Index from '@/pages/index/index'

// 登录页面
import Login from '@/pages/login/login'
// 注册页面
import Register from '@/pages/register/register'
// 领取额度
import GetQuota from '@/pages/get-quota/get-quota'
// 元信大额，填写资料
import FillInformation from '@/pages/fill-information/fill-information'
import NewFillInformation from '@/pages/new-fill-infomation/new-fill-information'
import FillInformationSuccess from '@/pages/fill-information/components/success/success'

// api回调授权成功中转页
import FillInformationSuccessTransfer from '@/pages/fill-information/components/success/components/transfer/transfer'

// 授权页
import FillInformationCommonAuthorize from '@/pages/fill-information/components/authorize-commom-page/authorize-commom-page'
// 提交表单后过渡动画页
import FillInformationCommonSubmitWaiting from '@/pages/fill-information/components/submit-waiting/submit-waiting'
// 用户重新匹配机构过渡页
import RematchWaiting from '@/pages/fill-information/components/rematch-waiting/rematch-waiting'
// 针对某个产品的授权页
import FillInformationAuthorize from '@/pages/fill-information/components/authorize-page/authorize-page'
// 点击H5广告后跳转的授权页
import JointAuthLogin from '@/pages/fill-information/components/joint-auth-login/joint-auth-login'
// 等待人脸识别
import Waiting from '@/pages/fill-information/components/waiting/waiting'
// 审核新闻页面
import AuditNew from '@/pages/audit-new/audit-new'
// 审核新闻详情页面
import AuditNewDetail from '@/pages/audit-new/components/detail/detail'
// 隐私协议
import PrivacyAgreement from '@/components/privacy-agreement/privacy-agreement'
// 注册协议
import RegisterAgreement from '@/components/register-agreement/register-agreement'
// 注册协议
import InformationAgreement from '@/components/infomation-agreement/information-agreement'
// 个人信息授权书
import InfoAuditAgreement from '@/components/info-audit-agreement/info-audit-agreement'
// 澄清公告
import ClarificationAnnouncement from '@/components/clarification-announcement/clarification-announcement'
// 聚客融-通用授权协议
import JukerongGeneralAgreement from '@/components/jkr-general-agreement/jkr-general-agreement'
// 聚客融-非大学生承诺函
import JukerongStudentAgreement from '@/components/jkr-student-agreement/jkr-student-agreement'
// 聚客融-隐私协议
import JukerongPrivacyAgreement from '@/components/jkr-privacy-agreement/jkr-privacy-agreement'
// 聚客融-注册协议
import JukerongRegisterAgreement from '@/components/jkr-register-agreement/jkr-register-agreement'
// 审核-隐私协议
import AuditAgreementPrivacy from '@/pages/audit-agreement/privacy/privacy'
// 审核-注册协议
import AuditAgreementRegister from '@/pages/audit-agreement/register/register'
// 审核-联系内容
import AuditAgreementContact from '@/pages/audit-agreement/contact/contact'
// 失效页面
import Failure from '@/pages/failure/failure'
// 有信详情页
import YouXinDetail from '@/pages/you-xin-detail/you-xin-detail'
import DownloadPage from '@/pages/download/download'
// 有钱掌柜协议
import YqShopkeeperAgreement from '@/pages/yq-shopkeeper-agreement/yq-shopkeeper-agreement'
// 申请借款
const LoanApplication = React.lazy(() => import('@/pages/loan-application/loan-application'))
// 个人资料
const PersonalData = React.lazy(() => import('@/pages/my/components/personal-data/personal-data'))
// 申请记录
const ApplicationRecord = React.lazy(() => import('@/pages/my/components/application-record/application-record'))
// 内嵌页
const InLinePage = React.lazy(() => import('@/pages/inline-page/inline-page'))
// 注册成功页面
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const RegisterSuccess = React.lazy(() => import('@/pages/register/components/success/success'))
// 下载应用
const Download = React.lazy(() => import('@/pages/register/components/download/download'))

// 测试页面
const Test = React.lazy(() => import('@/pages/test/test'))

// CRM员工登录页
const CrmLogin = React.lazy(() => import('@/pages/crm-login/crm-login'))

// 上游协议填充页
const ProtocolFillInformation = React.lazy(() => import('@/pages/protocol-fill-information/protocol-fill-information'))

// 从马甲包跳转过来需展示的广告页
const Advertising = React.lazy(() => import('@/pages/yqzs-app/advertising/advertising'))
// 借款
const BorrowMoney = React.lazy(() => import('@/pages/yqzs-app/borrow-money/borrow-money'))
// 我的
const My = React.lazy(() => import('@/pages/yqzs-app/my/my'))
// 申请记录
const SafeCenter = React.lazy(() => import('@/pages/yqzs-app/my/components/safe-center/safe-center'))
// 注销账号
const CancelAccount = React.lazy(() => import('@/pages/yqzs-app/my/components/safe-center/components/cancel-account/cancel-account'))

// 从马甲包跳转过来需展示的360渠道专属页面
const ApplyHome = React.lazy(() => import('@/pages/360-b/apply_home/apply_home'))
const ApplyLimit = React.lazy(() => import('@/pages/360-b/apply_limit/apply_limit'))
const ApplyAuthPhoto = React.lazy(() => import('@/pages/360-b/apply_auth_photo/apply_auth_photo'))
const ApplyAuthFace = React.lazy(() => import('@/pages/360-b/apply_auth_face/apply_auth_face'))
const ApplyResult = React.lazy(() => import('@/pages/360-b/apply_result/apply_result'))
const ApplyAuthForm = React.lazy(() => import('@/pages/360-b/apply_auth_form/apply_auth_form'))
const ApplyMy = React.lazy(() => import('@/pages/360-b/apply_my/apply_my'))

export interface MenuRoute {

    /** 标题 */
    title?: string,

    /** 是否要求登录 */
    requireLogin?: boolean,

    /** 是否不在菜单中显示 */
    hideInMenu?: boolean,

    /** 路由参数 */
    routeProps: RouteProps,

    /** 子菜单 */
    children?: MenuRoute[],

    /** 菜单图标 */
    icon?: string,

    /** 父级菜单对象 */
    parent?: MenuRoute | null,

    /** 完整路径 */
    fullPath?: string,

    /** 是否使用菜单栏布局 */
  hasMainLayout?: boolean,
}

export const routes: MenuRoute[] = [
  {
    routeProps: {
      path: '/',
      element: <App />,
    },
    children: [
      {
        routeProps: {
          index: true,
          element: <DownloadPage />
        },
        title: '首页',
        // hasMainLayout: true
      },
      {
        routeProps: {
          path: 'my',
        },
        children: [
          {
            routeProps: {
              index: true,
              element: <My />
            },
            title: '我的',
            hasMainLayout: true,
          },
          {
            routeProps: {
              path: 'personal-data',
              element: <PersonalData />
            },
          },
          {
            routeProps: {
              path: 'application-record',
              element: <ApplicationRecord />
            }
          },
          {
            routeProps: {
              path: 'safe-center',
              element: <SafeCenter />
            },
          },
          {
            routeProps: {
              path: 'cancel-account',
              element: <CancelAccount />
            }
          }
        ]
      },
      {
        routeProps: {
          path: 'login',
          element: <Login />
        },
        title: '登录',
      },
      {
        routeProps: {
          path: 'loan-application',
          element: <LoanApplication />
        },
        title: '申请借款'
      },
      {
        routeProps: {
          path: 'inline-page',
          element: <InLinePage />
        },
        title: ''
      },
      {
        routeProps: {
          path: 'audit-new',
        },
        title: '每日新闻',
        children: [
          {
            routeProps: {
              index: true,
              element: <AuditNew />
            },
            title: '每日新闻'
          },
          {
            routeProps: {
              path: 'detail',
              element: <AuditNewDetail />
            },
            title: '每日新闻'
          }
        ]
      },
      {
        routeProps: {
          path: 'register',
        },
        title: '元信',
        children: [
          {
            routeProps: {
              index: true,
              element: <Register />
            },
            title: '元信'
          },
          {
            routeProps: {
              path: 'success',
              element: <Download />
            },
            title: '元信'
          }
        ]
      },
      { // 领取额度
        routeProps: {
          path: 'getQuota',
          element: <GetQuota />,
        },
        title: '元信'
      },
      // 聚客融需要用/h5?source=BryFpJ来访问注册页面
      {
        routeProps: {
          path: 'h5',
          element: <GetQuota />,
        },
        title: '元信'
      },
      {
        routeProps: {
          path: 'fill-information',
        },
        title: '填写资料',
        children: [
          {
            routeProps: {
              index: true,
              element: <FillInformation />
            },
            title: '填写资料'
          },
          {
            routeProps: {
              path: 'waiting',
              element: <Waiting />
            },
            title: '元信'
          },
          {
            routeProps: {
              path: 'success',
              element: <FillInformationSuccess />
            },
            title: '审核成功'
          },
          {
            routeProps: {
              path: 'authorize',
              element: <FillInformationAuthorize />
            },
            title: '授权'
          },
          {
            routeProps: {
              path: 'common-authorize',
              element: <FillInformationCommonAuthorize />
            },
            title: '授权'
          },
          {
            routeProps: {
              path: 'waiting-result',
              element: <FillInformationCommonSubmitWaiting />
            },
            title: '授权'
          },
          {
            routeProps: {
              path: 'joint-auth-login',
              element: <JointAuthLogin />
            },
            title: '授权'
          },
          {
            routeProps: {
              path: 'rematch-waiting',
              element: <RematchWaiting />
            },
            title: '有钱来'
          },
          {
            routeProps: {
              path: 'success-transfer',
              element: <FillInformationSuccessTransfer />
            },
            title: '有钱来'
          },
        ]
      },
      // 测试渠道
      {
        routeProps: {
          path: 'new-fill-information',
          element: <NewFillInformation />
        },
        title: '填写资料'
      },
      {
        // 注册协议
        routeProps: {
          path: 'register-agreement',
          element: <RegisterAgreement />
        },
        title: '元信'
      },
      {
        // 隐私协议
        routeProps: {
          path: 'privacy-agreement',
          element: <PrivacyAgreement />
        },
        title: '元信'
      },
      {
        // 个人信息共享授权协议
        routeProps: {
          path: 'information-agreement',
          element: <InformationAgreement />
        },
        title: '元信'
      },
      {
        // 个人信息授权
        routeProps: {
          path: 'info-audit-agreement',
          element: <InfoAuditAgreement />
        },
        title: '元信'
      },
      {
        // 聚客融-通用授权协议
        routeProps: {
          path: 'jkr-general-agreement',
          element: <JukerongGeneralAgreement />
        },
        title: '聚客融'
      },
      {
        // 聚客融-非大学生承诺函
        routeProps: {
          path: 'jkr-student-agreement',
          element: <JukerongStudentAgreement />
        },
        title: '聚客融'
      },
      {
        // 聚客融-隐私协议
        routeProps: {
          path: 'jkr-privacy-agreement',
          element: <JukerongPrivacyAgreement />
        },
        title: '聚客融'
      },
      {
        // 聚客融-注册协议
        routeProps: {
          path: 'jkr-register-agreement',
          element: <JukerongRegisterAgreement />
        },
        title: '聚客融'
      },
      {
        // 澄清公告
        routeProps: {
          path: 'clarification-announcement',
          element: <ClarificationAnnouncement />
        },
        title: '元信'
      },
      {
        routeProps: {
          path: 'audit-agreement-privacy',
          element: <AuditAgreementPrivacy />
        }
      },
      {
        routeProps: {
          path: 'audit-agreement-register',
          element: <AuditAgreementRegister />
        }
      },
      {
        routeProps: {
          path: 'audit-agreement-contact',
          element: <AuditAgreementContact />
        }
      },
      {
        routeProps: {
          path: 'you-xin-detail',
          element: <YouXinDetail />
        },
        title: '有钱来'
      },
      {
        routeProps: {
          path: 'download',
          element: <DownloadPage />
        },
        title: ''
      },
      {
        routeProps: {
          path: 'failure',
          element: <Failure />
        },
        title: '元信',
      },
      {
        // CRM登录
        routeProps: {
          path: 'crm-login',
          element: <CrmLogin />
        },
        title: 'CRM登录'
      },
      {
        // 协议
        routeProps: {
          path: 'protocol-fill-information',
          element: <ProtocolFillInformation />
        },
        title: '协议'
      },
      {
        // 有钱掌柜协议
        routeProps: {
          path: 'yq-shopkeeper-agreement',
          element: <YqShopkeeperAgreement />
        },
        title: '有钱掌柜'
      },
      { // 从马甲包跳转过来需展示的广告页
        routeProps: {
          path: 'advertising',
          element: <Advertising />,
        },
        title: '有钱助手',
        hasMainLayout: true
      },
      {
        routeProps: {
          path: 'borrow-money',
          element: <BorrowMoney />
        },
        title: '借款',
        hasMainLayout: true
      },
      // 从马甲包跳转过来需展示的360渠道专属页面
      {
        routeProps: {
          path: 'apply_home',
          element: <ApplyHome />,
        },
        title: '有钱助手'
      },
      {
        routeProps: {
          path: 'apply_limit',
          element: <ApplyLimit />,
        },
        title: '有钱助手'
      },
      {
        routeProps: {
          path: 'apply_auth_form',
          element: <ApplyAuthForm />,
        },
        title: '有钱助手'
      },
      {
        routeProps: {
          path: 'apply_auth_photo',
          element: <ApplyAuthPhoto />,
        },
        title: '有钱助手'
      },
      {
        routeProps: {
          path: 'apply_result',
          element: <ApplyResult />,
        },
        title: '有钱助手'
      },
      {
        routeProps: {
          path: 'apply_my',
          element: <ApplyMy />,
        },
        title: '有钱助手'
      },
      // {
      //   routeProps: {
      //     path: 'apply_auth_face',
      //     element: <ApplyAuthFace />,
      //   },
      //   title: '有钱助手'
      // },
    ]
  }
]

if (process.env.NODE_ENV === 'development') {
  routes[0].children?.push(...[
    {
      routeProps: {
        path: 'test',
        element: <Test />
      },
      title: '测试页面',
      hideInMenu: true
    }
  ])
}

// 打平的路由数组
export const flattenRoutes: MenuRoute[] = []
// 完整路径-路由对象 map
const routesMap: Record<string, MenuRoute> = {}
// 递归路由，添加页面权限控制和父节点、完整路径
traverseTree(routes, (route, parent) => {
  // if (route.requireLogin && route.routeProps.element) {
  //   route.routeProps.element = <PermissionRoute route={route}>{route.routeProps.element}</PermissionRoute>
  // }
  route.parent = parent
  // 完整路径
  let fullPath: string | undefined
  if (parent) {
    fullPath = route.routeProps.index ? parent.fullPath : `${(parent.fullPath || '').replace(/\/$/, '')}/${route.routeProps.path || ''}`
  } else {
    fullPath = route.routeProps.path
  }
  route.fullPath = fullPath
  flattenRoutes.push(route)
  if (fullPath) {
    routesMap[fullPath] = route
  }
})

// 获取某个路由对象
export const getRoute: (fullPath: string) => MenuRoute | null = function getRoute(fullPath) {
  return routesMap[fullPath] || null
}

const Router = () => {
  // 渲染路由组件
  const renderRoute = (route: MenuRoute) => {
    const children = route.children?.map(childMenu => renderRoute(childMenu))
    return <Route key={route.fullPath || '/'} {...route.routeProps}>{children}</Route>
  }
  return (
    // <Suspense fallback={ <LoadingPage />}>
    <Suspense>
      <BrowserRouter>
        <Routes>
          {
            routes.map(renderRoute)
          }
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default Router
